import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import StandardToolPage from './StandardToolPage';
import EReader from '../devices/EReader';
const WritePage =  (props) => {





	return (
		<StandardToolPage 
			leftContent={<div>left</div>}
			mainContent={<div>main</div>}
			rightContent={<EReader/>}
			//onLogout={onLogout}
		>
		
		</StandardToolPage>
	);
};


export default inject('stores')(observer(WritePage));