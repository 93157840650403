export default {
    version:'1.0.48',
    functionNamePrefix:'bookmerlin-app',
    //environment:'dev',
    helpContentIds: {
        ADD_FIRST_BOXSET : '',
        ADD_FIRST_BOXSET: '',
        TRASHBIN_INFO: {
            partId: 'dummyPartId'
        },
        snapshots : 'articles/features/snapshots/',
        sharing : 'articles/features/sharing/'
        
    },
    HIDE_MODE:true,
    MAX_ATTACHMENT_SIZE: 5000000,
    bookApp: {
    CONTENT_TEMPLATES_DRAFT_ID:'CONTENT_TEMPLATE_001',
    CONTENT_TEMPLATES_DRAFT_IDx:'6c59fad0-18df-11e8-9fcb-034be2856a0b'
    
    },
    templates:{
    DEFAULT_TEMPLATES_BOOK_DRAFT_ID:'53402cd0-2db4-11eb-82fe-3ba44fd6c87e',
    STARTER_PAGE_ID_BLURBS:'8f58cf60-1c00-11e8-b9d4-65469101a63a',
    STARTER_PAGE_ID_COPYRIGHT:'907b0840-1c00-11e8-b9d4-65469101a63a'
    },
    //RECIPE_BOOK_ID:'12056660-1726-11e8-aa7c-73a5a81db9ef',
    RECIPE_BOOK_ID:'RECIPE_BOOK',
    DEFAULT_RECIPE_DRAFT_ID:'60345a50-1be3-11e8-9957-6bc3fcbf48cd',
    DEFAULT_BOOK_DRAFT_TEMPLATE_ID:'6c3e2fc0-377b-11e8-8bb4-f1ee99644cda',
    DEFAULT_BOOK_DRAFT_RECIPE_ID:'bookDraftDefault001',
    DEFAULT_BOOK_DRAFT_ID_OF_INFLATABLE_TEMPLATES:'BOOK_MERLIN_INFLATABLES',
    DEFAULT_BOOK_ID_OF_INFLATABLE_TEMPLATES:'abc43010-2c83-11e9-8274-437692f392cf',
    
    //TODO: Upload our own missing image image
    //MISSING_IMAGE:'https://renderman.pixar.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png',
    images:{
    //RESIZER_GATEWAY:'https://c22t1rqd15.execute-api.us-east-1.amazonaws.com/dev',
    MISSING_IMAGE_NAME:'image-not-found.png'
    },
    PRODUCT_ID_BOOK_MERLIN_PRO:'BOOK_MERLIN_PRO',
    PRODUCT_ID_BOOK_MERLIN_BASIC:'BOOK_MERLIN_BASIC',
    PLAN_ID_DAILY:'DAILY',
    PLAN_ID_MONTHLY:'MONTHLY',
    PLAN_ID_YEARLY:'YEARLY',
    STRIPE_PK:'pk_test_yZs7cZdfsBgunb4gmdczQLgX',
    //STRIPE_PK:'pk_live_0pGj3kmOKz40qsitYwUtgQGq00GmChar35',
    STARTER_TEMPLATES: {
        COPYRIGHT:'69b6f110-8845-11eb-875b-61baa9d4ff27',
        DEDICATION:'6c23f970-8845-11eb-875b-61baa9d4ff27',
        BLURBS:'e8237190-8845-11eb-bc61-c1dfc82fe531',
        VOLUME:'7665f450-884b-11eb-9f04-b958124b107a',
        PART:'77dc40a0-884b-11eb-9f04-b958124b107a',
        CHAPTER:'79102cc0-884b-11eb-9f04-b958124b107a',
        SCENE:'a12b3420-884b-11eb-9f04-b958124b107a',
        INTRODUCTION:'515546c0-884b-11eb-9f04-b958124b107a',
        TITLEPAGE:'4d2b4c20-884b-11eb-9f04-b958124b107a',
        TOC:'7a705900-884b-11eb-9f04-b958124b107a',
        NOTES:'96016290-884b-11eb-9f04-b958124b107a',
        FULLPAGEIMAGE:'7cf7a020-884b-11eb-9f04-b958124b107a',
        UNTYPED:'7ea52910-884b-11eb-9f04-b958124b107a',
        BOOK:'7ea52910-884b-11eb-9f04-b958124b107a', //BOOK uses the content from UNTYPED. 
        ALSOBY:'9365f730-884b-11eb-9f04-b958124b107a',
        ABOUTAUTHOR:'8f32adc0-884b-11eb-9f04-b958124b107a',
        EPILOGUE:'87a19350-884b-11eb-9f04-b958124b107a',
        EPIGRAPH:'83780de0-884b-11eb-9f04-b958124b107a',
        AFTERWORD:'81982a50-884b-11eb-9f04-b958124b107a',
        PROLOGUE:'71e686b0-884b-11eb-9f04-b958124b107a',
        PREFACE:'6edfaf50-884b-11eb-9f04-b958124b107a',
        HALFTITLE:'4ef6bd50-884b-11eb-9f04-b958124b107a',
        FOREWORD:'6ba51dc0-884b-11eb-9f04-b958124b107a',
        DEDICATION:'6c23f970-8845-11eb-875b-61baa9d4ff27',
        ACKNOWLEDGEMENTS:'66dbd5e0-884b-11eb-9f04-b958124b107a'
    },
    editing: {
        MAX_EDITOR_NOTE_LENGTH: 2500
    }



    
}