import {
    observable,
    action,
    computed,
    runInAction,
    extendObservable,
    makeObservable,
} from 'mobx';

import { BookDraft, Book } from '../domain/bookModel';

class BookStore {
	
    loggedIn = false;
    authoredBooks = [new Book({title:'Book1', id:'book1'}), new Book({title:'Book2', id:'book2'}), new Book({title:'Book3', id:'book3'})];
    writingBookDraft = new BookDraft();
    constructor() {
        makeObservable(this, {
            loggedIn: observable,
            writingBookDraft: observable,
            authoredBooks: observable

        });
    }
}

export default BookStore;