import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const StandardDialog = (props) => {


    //const [open, setOpen] = React.useState(false);
    const { open, title, content, actions, onClose} = props;
  
    return (
        <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent sx={{
            position:'relative',
            height:'100%'
        }}>
          {content}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </div>
    );
};


export default inject('stores')(observer(StandardDialog));
