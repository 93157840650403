import React, { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import StandardToolPage from './StandardToolPage';
import { generate } from '../uiUtils';

const WritePage =  (props) => {





	return (
		<StandardToolPage 
			leftContent={<div>left</div>}
			mainContent={<div>main</div>}
			rightContent={<div>right</div>}
			//onLogout={onLogout}
		>
		
		</StandardToolPage>
	);
};


export default inject('stores')(observer(WritePage));