import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import StandardListItem from './StandardListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

const SharedBookListItem = (props) => {


    const leftColumn = <ListItemAvatar >
        <img
            style={{
                height: '4em',
                width: 'auto'
            }}
            src={props.coverImage} />
    </ListItemAvatar>

    const actions = [
    <IconButton edge="end" aria-label="delete" onClick={()=>{
        alert('write');
    }}>
        <HistoryEduIcon />
    </IconButton>]

    return (
        <StandardListItem
            leftColumn={leftColumn}
            primaryText={props.title}
            actions={actions} />
    );
};


export default inject('stores')(observer(SharedBookListItem));