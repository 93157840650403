import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import DeleteIcon from '@mui/icons-material/Delete';



export const mainListItems = (

  

  <div>
	{/*<ListSubheader inset>Lists</ListSubheader>*/}
    <ListItem button>
      <ListItemIcon>
        <HomeIcon color='primary'/>
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
      <MenuBookIcon />
        
      </ListItemIcon>
      <ListItemText primary="Books" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ContentCopyIcon />
      </ListItemIcon>
      <ListItemText primary="Boxsets" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIndIcon />
      </ListItemIcon>
      <ListItemText primary="Edit Requests" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Contacts" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
	{/*<ListSubheader inset>Tools</ListSubheader>*/}
    <ListItem button>
      <ListItemIcon>
        <HistoryEduIcon />
      </ListItemIcon>
      <ListItemText primary="Write" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <NoteAltIcon />
      </ListItemIcon>
      <ListItemText primary="Edit" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <FormatPaintIcon />
      </ListItemIcon>
      <ListItemText primary="Format" />
    </ListItem>




  </div>
);

export const trashListItems = (
  <div>
	
	{/*<ListSubheader inset>Trash</ListSubheader>*/}
    <ListItem button>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      <ListItemText primary="Trash" />
    </ListItem>




  </div>
);
