import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import StandardForm from '../StandardForm';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import StandardTextField from '../formFields/StandardTextField';


const BookDetailForm = (props) => {

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    });

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            border: '1px solid #ced4da',
            fontSize: 16,
            width: 'auto',
            padding: '10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const RedditTextField = styled((props) => (
        <TextField InputProps={{ disableUnderline: true }} {...props} />
    ))(({ theme }) => ({
        '& .MuiFilledInput-root': {
            border: '1px solid #e2e2e1',
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const ValidationTextField = styled(TextField)({
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
    });

    const items = [
        //Here is why the semi-transparent filled version is best
        //https://medium.com/google-design/the-evolution-of-material-designs-text-fields-603688b3fe03
        <StandardTextField label="Title" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>, 
        <StandardTextField label="Subtitle" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Author" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="eBook ISBN" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Print ISBN" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Kindle Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Apple Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Google Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Kobo Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Nook Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="BookBub Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Custom Store Link" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Kindle Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Apple Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Google Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Kobo Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Nook Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="BookBub Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <StandardTextField label="Custom Book ID" fullWidth id="filled-basic" variant="filled" size="small" sx={{
            
        }}/>,
        <div>Created:</div>,
        <div>delete book button</div>
        /*<FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
                Bootstrap
            </InputLabel>
            <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" />
        </FormControl>,
        <RedditTextField
            label="Reddit"
            defaultValue="react-reddit"
            id="reddit-input"
            variant="filled"
            style={{ marginTop: 11 }}
        />,
        <CssTextField label="Custom CSS" id="custom-css-outlined-input" />,
        <ValidationTextField
            label="CSS validation style"
            required
            variant="outlined"
            defaultValue="Success"
            id="validation-outlined-input"
        />*/
    ]

    return (
        <StandardForm items={items} />
    );
};


export default inject('stores')(observer(BookDetailForm));
