/* eslint-disable */
		
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vPxoEgdC5",
    "aws_user_pools_web_client_id": "35ce4t5ehn823pm7o9smif13qe",
    
};


export default awsmobile;
