import { useState, useEffect } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import Amplify from '@aws-amplify/core';
import { Auth } from "aws-amplify";
import config from '../../aws-exports';

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});


   

export function useAuthStatus() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
	
    function handleStatusChange(status) {
      setIsAuthenticated(status.isAuthenticated);
    }

	



	 Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(data => {
        let user = {username:data.username,...data.attributes}
        console.log(user);
		if(user.sub){
			setIsAuthenticated(true);
		}else{
			setIsAuthenticated(false);
		}
        //this.props.onIdentityIdAvailable(user.sub);
         
	});





    /*ChatAPI.subscribeToFriendStatus(friendID, handleStatusChange);
    return () => {
      ChatAPI.unsubscribeFromFriendStatus(friendID, handleStatusChange);
    };*/


  });

  return isAuthenticated;
}