import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
require('html5-device-mockups/dist/device-mockups.min.css') 
const EReader = (props) => {



    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap', width: '100%', height: '100%'
        }}>
            <div class="device-wrapper" style={{
                maxWidth:'100%'
            }}>
                <div class="device" data-device="galaxyTab4" data-orientation="portrait" data-color="black"  style={{
                maxWidth:'100%'
            }}>
                    <div class="screen" style={{
                        backgroundColor:'red'
                    }}>
                         PUT CONTENTS HERE 
                    </div>
                   
                </div>
            </div>

        </div>
    );
};


export default inject('stores')(observer(EReader));
