import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';

const StandardForm = (props) => {

   
   

    const wrappedItems = props.items.map((item) => {

        return <div style={{
            width: '100%',
            marginBottom:'1em',
        }}>{item}</div>
    })

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap', width: '100%', height: '100%'
        }}>
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'

            }}>
                <Typography variant="overline" display="block" >title</Typography>
                <InfoOutlinedIcon sx={{
                    marginLeft: '1em'
                }} />
            </div>
            {wrappedItems}

        </div>
    );
};


export default inject('stores')(observer(StandardForm));
