import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Image from 'mui-image'
import { Typography } from '@mui/material';

const StandardImageSelector = (props) => {

    const item = {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
        rows: 2,
        cols: 2,
    }

    return (
        <div sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            {/*https://www.npmjs.com/package/mui-image*/}
            <div style={{
                display:'flex',
                width:'100%',
                justifyContent:'center',
                alignItems:'center'
                
            }}>
                <Typography variant="overline" display="block" >title</Typography>
                <InfoOutlinedIcon sx={{
                    marginLeft:'1em'
                }}/>
            </div>
            <Image 
                sx={{ display: { sm: 'block', lg: 'block', md:'block' }}}
                style={{ borderRadius: 2 }}
                className="custom-class"
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                showLoading 
                errorIcon />

            {/*<ImageListItem key={item.img}>
                <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                />
    </ImageListItem>*/}

        </div>
    );
};


export default inject('stores')(observer(StandardImageSelector));
