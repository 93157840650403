import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { mainListItems, secondaryListItems, trashListItems } from '../listItems';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import DeleteIcon from '@mui/icons-material/Delete';

const SideMenu = (props) => {

  const navigate = useNavigate();
  let location = useLocation();

  const drawerWidth = 240;
  const displayBig = {

    xs: 'none',
    sm: 'none',
    md: 'flex',
    lg: 'flex',
    xl: 'flex',
    tablet: 'flex',
    laptop: 'flex',
    desktop: 'flex',

  }

  const displaySmall = {

    xs: 'flex',
    sm: 'flex',
    md: 'none',
    lg: 'none',
    xl: 'none',
    tablet: 'none',
    laptop: 'none',
    desktop: 'none',

  }

  const toggleDrawer = () => {
    props.setOpen(!props.open);
  };

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'props.open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!props.open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );

  const isRoute = (pathname) => {
    if (pathname == location.pathname) {
      return 'primary';
    }

    return 'inherit';
  }

  return (
    <Drawer sx={{
      display: displayBig
    }}
      variant="permanent" open={props.open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <div>
          {/*<ListSubheader inset>Lists</ListSubheader>*/}
          <ListItem button onClick={() => {

            navigate("/");

          }} >

            <ListItemIcon>
              <HomeIcon color={isRoute('/')} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>



          <ListItem button onClick={() => {

            navigate("/books");

          }}>
            <ListItemIcon>
              <MenuBookIcon color={isRoute('/books')} />

            </ListItemIcon>
            <ListItemText primary="Books" />
          </ListItem>



          <ListItem button onClick={() => {

            navigate("/boxsets");

          }}>
            <ListItemIcon>
              <ContentCopyIcon color={isRoute('/boxsets')} />
            </ListItemIcon>
            <ListItemText primary="Boxsets" />
          </ListItem>



          <ListItem button onClick={() => {

            navigate("/editrequests");

          }}>
            <ListItemIcon>
              <AssignmentIndIcon color={isRoute('/editrequests')} />
            </ListItemIcon>
            <ListItemText primary="Edit Requests" />
          </ListItem>



          <ListItem button onClick={() => {

            navigate("/connections");

          }}>
            <ListItemIcon>
              <PeopleIcon color={isRoute('/connections')} />
            </ListItemIcon>
            <ListItemText primary="Connections" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>

        <div>
          {/*<ListSubheader inset>Tools</ListSubheader>*/}
          <ListItem button onClick={() => {

            navigate("/write");

          }}>
            <ListItemIcon>
              <HistoryEduIcon color={isRoute('/write')} />
            </ListItemIcon  >
            <ListItemText primary="Write" />
          </ListItem>
          <ListItem button onClick={() => {

            navigate("/edit");

          }}>
            <ListItemIcon>
              <NoteAltIcon color={isRoute('/edit')} />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItem>
          <ListItem button onClick={() => {

            navigate("/format");

          }}>
            <ListItemIcon>
              <FormatPaintIcon color={isRoute('/format')} />
            </ListItemIcon>
            <ListItemText primary="Format" />
          </ListItem>




        </div>
      </List>
      <Divider />
      <List>

        <div>

          {/*<ListSubheader inset>Trash</ListSubheader>*/}
          <ListItem button>
            <ListItemIcon onClick={() => {

              navigate("/trashbin");

            }}>
              <DeleteIcon color={isRoute('/trashbin')} />
            </ListItemIcon>
            <ListItemText primary="Trash" />
          </ListItem>




        </div>

      </List>
    </Drawer>
  );
};


export default inject('stores')(observer(SideMenu));
