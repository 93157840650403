import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';


const StandardListItem = (props) => {

	const { onSelected, wholeRowSelect } = props;
	const actionItems = props.actions.map((item)=>{

		return <span style={{paddingLeft:'.5em'}}>{item}</span>
	})

	return (

		<ListItem
			
			sx={{
				width: '100%',
			}}
			secondaryAction={
				<div>
					{actionItems}
				</div>
			}
			onClick={()=>{
				if(wholeRowSelect){
					onSelected();
				}
			}}
		>

			{props.leftColumn}
			<ListItemText
				primary={props.primaryText}
				secondary={false ? 'Secondary text' : null}
			/>
		</ListItem>
	);
};


export default inject('stores')(observer(StandardListItem));