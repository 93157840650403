export class User {

    type = 'User';
    id;

    constructor(args) {
        this.id = 'user001';
    }

}
export class Book {

    type = 'Book';
    id;
    bookDraft;
    title;

    constructor(args) {
        this.bookDraft = new BookDraft();
        this.id = args.id;
        this.title = args.title;
    }

}
        
        
export class BookDraft {

    type = 'BookDraft';
    id;
    bookDraftDocumentParts;
    mappedImages = [];
    constructor() {


        // this.bookDraftDocumentParts = [0,1,3,4,5,6,7,8,9,1011,12,13,14,15,16].map((i)=>{

        //     return new BookDraftDocumentPart({
        //         children:[
        //             new BookDraftDocumentPart(),
        //             new BookDraftDocumentPart()
        //         ]
        //     });
        // });

        this.bookDraftDocumentParts = [];

        let volume1 = new BookDraftDocumentPart({
            bookDraft:this,
            title: 'Volume 1',
            type:'VOLUME',
            children: [
                new BookDraftDocumentPart({
                    bookDraft:this,
                    title: 'Chapter 1',
                    type:'CHAPTER',
                    children: [
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 1',
                            type:'SCENE'
                        }),
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 2',
                            type:'SCENE'
                        })
                    ]
                }),
                new BookDraftDocumentPart({
                    bookDraft:this,
                    title: 'Chapter 2',
                    type:'CHAPTER',
                    children: [
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 3',
                            type:'SCENE'
                        }),
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 4',
                            type:'SCENE'
                        })
                    ]
                })
            ]
        });

        let volume2 = new BookDraftDocumentPart({
            bookDraft:this,
            title: 'Volume 2',
            type:'VOLUME',
            children: [
                new BookDraftDocumentPart({
                    bookDraft:this,
                    title: 'Chapter 1',
                    type:'CHAPTER',
                    children: [
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 1',
                            type:'SCENE'
                        }),
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 2',
                            type:'SCENE'
                        })
                    ]
                }),
                new BookDraftDocumentPart({
                    bookDraft:this,
                    title: 'Chapter 2',
                    type:'CHAPTER',
                    children: [
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 3',
                            type:'SCENE'
                        }),
                        new BookDraftDocumentPart({
                            bookDraft:this,
                            title: 'scene 4',
                            type:'SCENE'
                        })
                    ]
                })
            ]
        });


        this.bookDraftDocumentParts.push(volume1);
        this.bookDraftDocumentParts.push(volume2);

    }


}

export class BookDraftDocumentPart {

    type = 'BookDraftDocumentPart';
    bookDraft;
    id;
    documentPart;
    children = [];

    constructor(args) {
        if (!args) return;

        let { children = [] , title, type, bookDraft} = args;
        this.bookDraft = bookDraft;
        this.children = children;
        this.documentPart = new DocumentPart({title, type, bookDraftDocumentPart:this});

    }
}


export class DocumentPart {

    type = 'DocumentPart';
    id;
    title;
    type;
    bookDraftDocumentPart;
    delta = [
        { insert: 'Hello ' },
        { insert: 'World!', attributes: { bold: true } },
        { insert: 'Isnt this the best thing you have ever seen?\n' }
    ]
    constructor(args) {
        this.bookDraftDocumentPart = args.bookDraftDocumentPart;
        this.title = args.title;
        this.type = args.type;

    }
}