import React, { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import StandardToolPage from './StandardToolPage';
import BookDraftEditor from '../editor/BookDraftEditor';
import StandardTree from '../StandardTree';

const WritePage = (props) => {



	/*
		mainContent={<BookDraftEditor/>}
		mainContent={<StandardSplitPane/>}
				
	*/


	return (
		<StandardToolPage
			leftContent={<StandardTree/>}
			mainContent={<BookDraftEditor bookDraft={props.stores.bookStore.writingBookDraft}/>}
			rightContent={<div>right</div>}
		//onLogout={onLogout}
		>

		</StandardToolPage>
	);
};


export default inject('stores')(observer(WritePage));