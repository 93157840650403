import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StandardTextField = (props) => {


    /*
          <TextField fullWidth id="filled-basic" label={props.label} variant="filled" size="small" sx={{
                  
            }}/>
    */

    return (
        <TextField id="outlined-basic" label={props.label} variant="outlined" fullWidth size="small" sx={{

        }} />



    );
};


export default inject('stores')(observer(StandardTextField));
