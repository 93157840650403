import React, { useState, useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import ToolbarItem from './ToolbarItem';
import Button from '@mui/material/Button';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ToolbarPopup from './ToolbarPopup';
import { Paper, Typography } from '@mui/material';
import EditingContext from '../EditingContext';
import uuid from 'uuid';
import Quill from 'quill';
import StandardDialog from '../../StandardDialog';
import DialogContentText from '@mui/material/DialogContentText';
import AuthorBooksList from '../../lists/AuthorBooksList';


const Parchment = Quill.import('parchment');
const $ = require("jquery");

const Toolbar = (props) => {

    const [selectedAlignment, setSelectedAlignment] = React.useState(0);
    const [alsobyDialogOpen, setAlsobyDialogOpen] = React.useState(false);

    const editingContext = useContext(EditingContext);
    const selected = false;
    const fontSize = 'small'

    console.log(editingContext);
    const selectAlignment = (index) => {
        console.log(index);
        setSelectedAlignment(index);
    }


    const handleInsertAlsobys = () => {

        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid.v1();

        const range = selectedQuill.getSelection();


        let alsobys = {
            records: [
                {
       
                    item:{
                          orderNumber:1,
                          id:uuid.v1(),
                          bookId:'book1',
                          title:"",
                          isStoreLink:'false',
                          subtitle:"",
                          coverUrl:"",
                          coverUrlType:"",
                          kindleIdentifier:"1",
                          appleIdentifier:"2",
                          nookIdentifier:"3",
                          koboIdentifier:"4",
                          googleIdentifier:"5",
                          customIdentifier:"6",
                          include:'true'
                        }
                    
                    }
            ]
        };


        selectedQuill.insertEmbed(range.index + 0, 'alsobys', {
            uuid: blotId,
            alsobys: JSON.stringify(alsobys),
            stores: props.stores,
            userStore: props.stores.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);

    }


    const handleInsertBlurbs = () => {


        const { selectedQuill, selectedDocumentPart } = editingContext;

        let blotId = uuid.v1();

        const range = selectedQuill.getSelection();

        let blurbs = {
            records: [{
                item: {
                    id: uuid.v1(),
                    orderNumber: 1,
                    comment: 'This is a great book!',
                    source: 'Fan Reader'
                }
            }]
        };

        selectedQuill.insertEmbed(range.index + 0, 'blurbs', {
            uuid: blotId,
            blurbs: JSON.stringify(blurbs),
            stores: props.stores,
            userStore: props.userStore,
            documentPart: selectedDocumentPart
        }, Quill.sources.USER);

        selectedQuill.setSelection(range.index + 1, Quill.sources.SILENT);




    }

    return (

        <Paper sx={{
            display: 'flex'
        }} >



            <StandardDialog
                open={alsobyDialogOpen}
                title={'Add an alsoby'}
                content={
                    <div style={{
                        height:'100%'
                    }}>
                        <DialogContentText id="alert-dialog-description">
                            Select a book to add
                        </DialogContentText>
                        <AuthorBooksList onSelected={(book)=>{
                            console.log(book);

                            let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");
                            console.log(blotDivId);

                            let node = $('#' + blotDivId)[0];
                            let blot = Parchment.find(node);
                            console.log(blot);
                            console.log(node.dataset.uuid);
                            console.log(node.dataset.alsobys);

                            
                            const { selectedQuill, selectedDocumentPart } = editingContext;
                            let newContributor = blot.addContributor({ bookId: book.id });

                            let alsobys = JSON.parse(node.dataset.alsobys);
                            console.log(alsobys);


                            let newArgs = {
                                stores: props.stores,
                                userStore: props.userStore,
                                documentPart: selectedDocumentPart,
                                uuid: node.dataset.uuid,
                                alsobys: JSON.stringify(alsobys)
                            }

                            blot.replaceWith('alsobys', newArgs);




                        }}/>
                    </div>}
                actions={
                    <div>
                        <Button onClick={() => { setAlsobyDialogOpen(false) }}>Disagree</Button>
                        <Button onClick={() => { setAlsobyDialogOpen(false) }} autoFocus>Close</Button>
                    </div>
                } 
                onClose={()=>{
                    setAlsobyDialogOpen(false);
                    //addAlsoBy();
                }}/>


            <div focusable='false' tabIndex={-1} id='master-toolbar' style={{
                'width': '100%'
            }}>

                <div focusable='false' tabIndex={-1} className='toolbar-wrapper' style={{
                    'display': 'flex',
                    justifyContent: 'space-around',
                    'flex-direction': 'row',
                    'flex-wrap': 'wrap',
                    'alignItems': 'center',
                    width: '100%',
                    border: '1px solid gray',
                    padding: '0em 0em .5em 0em'
                }}>


                    <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={editingContext.formatting.bold ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatUnderlinedIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatItalicIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatStrikethroughIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<SubscriptIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<SuperscriptIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={<FormatBoldIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />} />
                    <ToolbarItem icon={
                        <ToolbarPopup
                            onClick={(index) => {
                                selectAlignment(index)
                            }}
                            selectedIndex={selectedAlignment}
                            style={{
                                'height': '100%'
                            }}

                            buttonx={<FormatAlignLeftIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />}
                            icons={[
                                <FormatAlignLeftIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} />,
                                <FormatAlignCenterIcon fontSize={fontSize} color={(selectedAlignment == 1) ? 'primary' : 'disabled'} />,
                                <FormatAlignRightIcon fontSize={fontSize} color={(selectedAlignment == 2) ? 'primary' : 'disabled'} />,
                                <FormatAlignJustifyIcon fontSize={fontSize} color={(selectedAlignment == 3) ? 'primary' : 'disabled'} />
                            ]} />}
                    />

                    <ToolbarItem icon={
                        <ToolbarPopup
                            onClick={(index) => {

                            }}

                            style={{
                                'height': '100%'
                            }}

                            button={<FormatSizeIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />}
                            icons={[
                                <Typography variant="button" display="block" color={'disabled'}>SM</Typography>,
                                <Typography variant="button" display="block" color={'disabled'}>NM</Typography>,
                                <Typography variant="button" display="block" color={'disabled'}>LG</Typography>,
                                <Typography variant="button" display="block" color={'disabled'}>XL</Typography>
                            ]} />}
                    />

                    <ToolbarItem icon={
                        <ToolbarPopup
                            onClick={(index) => {

                            }}

                            style={{
                                'height': '100%'
                            }}

                            button={<WidgetsIcon fontSize={fontSize} color={selected ? 'primary' : 'disabled'} />}
                            icons={[
                                <span onClick={handleInsertBlurbs} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Blurbs</Typography></span>,
                                <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add TOC</Typography></span>,
                                <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Notes</Typography></span>,
                                <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Decorated Break</Typography></span>,
                                <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Contributors</Typography></span>,
                                <span style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Publisher</Typography></span>,
                                <span onClick={handleInsertAlsobys} style={{ display: 'flex' }}><WidgetsIcon fontSize={fontSize} color={(selectedAlignment == 0) ? 'primary' : 'disabled'} /><Typography sx={{ paddingLeft: '.5em' }} variant="button" display="block" color={'disabled'}>Add Alsobys</Typography></span>

                            ]} />}
                    />


                    <input style={{ 'display': 'none' }} id={'master_quillImageProcessor'} onClick={() => {
						let blotImageDivId = $("#master_quillImageProcessor").attr("blotImageDivId");
						this.updateImageBlot(blotImageDivId);

					}} />

                    <input style={{ 'display': 'none' }} id={'master_quillBookSelector'} onClick={(e) => {

                        e.stopPropagation();
                        e.preventDefault();

                        setAlsobyDialogOpen(true);
                        /*let allBooks = this.props.stores.bookStore.authoredBooks;

                        let blotDivId = $("#master_quillImageProcessor").attr("blotDivId");

                        let node = $('#' + blotDivId)[0];

                        //console.log(node.dataset.uuid);
                        console.log(node.dataset.alsobys);

                        let json = JSON.parse(node.dataset.alsobys);

                        let bookIds = json.records.filter(f => f.item.bookId != null).map((m) => m.item.bookId);
                        console.log(bookIds);

                        let availableBooks = [];

                        allBooks.forEach((book) => {


                            let inList = bookIds.filter(id => id == book.id);

                            if (inList.length == 0) {
                                availableBooks.push(book);
                            }

                        })

                        console.log(availableBooks);
                        this.setState({
                            showSelectBookDialogue: true,
                            availableBooks: availableBooks
                        });*/
                    }} />

                </div>
            </div>
        </Paper>
    );
};


export default inject('stores')(observer(Toolbar));
