import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import uuid from 'uuid';


const ToolbarPopup = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {selectedIndex, button} = props;

    let renderedButton = (button!=null)?button:props.icons[selectedIndex]
    const handleClick = (event, index) => {
        
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (index) => {
        
        setAnchorEl(null);
        props.onClick(index);
    };

    const iconMenuItems = props.icons.map((icon,index)=>{

        const color = (index!=null && index==selectedIndex)? 'primary' : 'disabled';
        
        return <MenuItem onClick={()=>{
            handleClose(index);
        }} >{icon}</MenuItem>
    })

   
    return (
        <div style={{
            textAlign:'center',
            verticalAlign:'middle'
        }}>
            <div
                id={"toolbar-selected-icon"+uuid.v1()}
                aria-controls={"toolbar-positioned-menu"+uuid.v1()}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{
                    
                }}
            >
                {renderedButton}
            </div>
            <Menu
                id={"toolbar-positioned-menu"+uuid.v1()}
                aria-labelledby={"toolbar-selected-icon"+uuid.v1()}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                
            >
                {iconMenuItems}
            </Menu>
        </div>

    );
};


export default inject('stores')(observer(ToolbarPopup));
