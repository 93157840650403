import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StandardTabs = (props) => {


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


  let tabTitles = props.titles.map((title, index) => {

    return <Tab label={title} {...a11yProps(index)} />
  })

  let tabPanels2 = props.content.map((contentItem, index) => {

    return <TabPanel value={value} index={index} sx={{ height: '100%' }}>
      {contentItem}
    </TabPanel>
  })

  let tabPanels = props.content.map((contentItem, index) => {

    if (value == index) {
      return <div value={value} index={index} style={{ height: '100%' }}>
        {contentItem}
      </div>
    }
    return <div></div>
  })


  return (
    <div style={{ borderBottom: 1, borderColor: 'divider', width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto">
          {tabTitles}
        </Tabs>
      </Box>

      {tabPanels}
    </div>

  );
};


export default inject('stores')(observer(StandardTabs));
