import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { Scrollbars } from 'react-custom-scrollbars-2';

const StandardList = (props) => {




    return (
        <Scrollbars 
            
            autoHide
            renderThumbHorizontal={()=>{
                return <span/>
            }}
            style={{ width: '100%', height: '100%', overFlow:'hidden' }}>


            <List dense={true} sx={{
                width: '100%',
                height:'100%'
            }}>

               {props.items}

            </List>

        </Scrollbars>


    );
};


export default inject('stores')(observer(StandardList));
