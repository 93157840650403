import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import QuillEditor from './QuillEditor';
import { Paper } from '@mui/material';
import { Typography } from '@material-ui/core';


const DocumentPartEditor = (props) => {

    let { bookDraftDocumentPart } = props;

    let sceneEditors = [];

    if(bookDraftDocumentPart.documentPart.type!='CHAPTER'){
        sceneEditors.push(<QuillEditor documentPart={bookDraftDocumentPart.documentPart} delta={bookDraftDocumentPart.documentPart.delta} />);
    }
    let documentPartEditors = [];

    if (bookDraftDocumentPart) {

        bookDraftDocumentPart.children.forEach((childBddp, index) => {

            if(childBddp.documentPart.type=='SCENE'){

                sceneEditors.push(<QuillEditor documentPart={childBddp.documentPart} delta={childBddp.documentPart.delta} showBreak={index==0?false:true}/>);

            }
            else{

                documentPartEditors.push(<DocumentPartEditor bookDraftDocumentPart={childBddp}/>);
           
            }
            

        });
    }

    return (
        <div>
        <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            overFlow: 'hidden',
            marginBottom:'.5em'
        }}>
            <Typography variant="h5">{bookDraftDocumentPart.documentPart.title}</Typography>
                
                {sceneEditors}

        </Paper>
                {documentPartEditors}
        </div>
    );
};


export default inject('stores')(observer(DocumentPartEditor));
