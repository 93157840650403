import React, { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import StandardListPage from './StandardListPage';
import StandardTabs from '../StandardTabs';
import StandardTabDetail from '../StandardTabDetail';
import BookDetailForm from '../detail/BookDetailForm';
import StandardForm from '../StandardForm';
import StandardList from '../StandardList';
import BookListItem from '../BookListItem';
import SharedBookListItem from '../SharedBookListItem';
import StandardDetail from '../detail/StandardDetail';
import StandardImageSelector from '../StandardImageSelector';
import StandardAccordian from '../StandardAccordian';
import { generate } from '../uiUtils';

const TrashPage =  (props) => {

const bookItems =  generate(<BookListItem title={'Star Wars'} coverImage={'https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg'}/>);
const sharedBookItems =  generate(<SharedBookListItem title={'Principia Mathematica'} coverImage={'https://images-na.ssl-images-amazon.com/images/I/31IdF5z0kWL._SX218_BO1,204,203,200_QL40_FMwebp_.jpg'}/>);
const leftContent = <StandardList items={bookItems}/>
const mainContent = <StandardForm/>

const tabsContent = [
	<StandardList items={bookItems}/>,
	<StandardList items={sharedBookItems}/>
]

const bookDetailLeftContent = [
	<StandardImageSelector/>,
	<StandardImageSelector/>
]

const bookDetailFormFields = [

]

const mainTabsContent = [
	<StandardTabDetail content={<StandardDetail leftContent={bookDetailLeftContent} rightContent={<BookDetailForm/>} title='' subtitle=''/>} />,
	<StandardTabDetail content={<StandardAccordian/>} />,
	<StandardTabDetail content={<div>content</div>} />,
	<StandardTabDetail content={<div>content</div>} />,
	<StandardTabDetail content={<div>content</div>} />,
	<StandardTabDetail content={<div>content</div>} />
]



	return (
		<StandardListPage 
			leftContent={'Trash left'}
			mainContent={'Trash Right'}
		>
		
		</StandardListPage>
	);
};


export default inject('stores')(observer(TrashPage));