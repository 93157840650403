import BookStore from './BookStore';
import UserStore from './UserStore';

class Stores {

	constructor(){

		
		this.initialize();
		
		

	}

	resetStores(){

		this.bookStore = new BookStore( );
		this.userStore = new UserStore( );

	}

	initialize(){
		this.bookStore = new BookStore( );
		this.userStore = new UserStore( );
		

	}




	

}

const stores = new Stores();
export default stores;