import React from 'react';
import './App.css';
import './styles.css'; 
import { useAuthStatus } from './components/auth/authHooks';
import { Provider } from 'mobx-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import stores from './stores';
import AppWrapper from './components/AppWrapper';

function App() {
	stores.bookStore.loggedIn = null;
	stores.bookStore.loggedIn = useAuthStatus();
	



	return (
		<div className="App">
			<Provider stores={stores} userStore={stores.userStore}>
				
			
				<AppWrapper />

			</Provider>

		</div>
	);
}

export default App;
