import {
    observable,
    action,
    computed,
    runInAction,
    extendObservable,
    makeObservable,
} from 'mobx';

import { User } from '../domain/bookModel';

class UserStore {
	
    loggedIn = false;
    user = new User();
    constructor() {
        makeObservable(this, {
            user: observable

        });
    }
}

export default UserStore;