import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import StandardList from '../StandardList';
import BookListItem from '../BookListItem';
import { generate } from '../uiUtils';

const AuthorBooksList = (props) => {


    const {authoredBooks} = props.stores.bookStore;
    console.log(props.stores.bookStore)
    const bookItems = authoredBooks.map((book)=>{
        return <BookListItem
        book={book}
        title={book.title}
        coverImage={'https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg'}
        showWriteIcon={false}
        showFormatIcon={false}
        wholeRowSelect={true}
        onSelected={(book)=>{
            console.log(book);
            props.onSelected(book);
        }}
        />
    });


    // const bookItems = generate(

    //     <BookListItem
    //         title={'Star Wars'}
    //         coverImage={'https://images-na.ssl-images-amazon.com/images/I/51EV7oJMlbL._SY291_BO1,204,203,200_QL40_FMwebp_.jpg'}
    //         showWriteIcon={false}
    //         showFormatIcon={false}
    //         showSelectIcon={true}
    //         />);

    console.log(bookItems);
    return (
        <div style={{
            height: '20em',
            border: '1px solid red'
        }}>
            <StandardList items={bookItems} />
        </div>

    );
};


export default inject('stores')(observer(AuthorBooksList));