import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import StandardSideMenu from './StandardSideMenu';
import StandardAppBar from './StandardAppBar';


const displayBig = {

  xs: 'none',
  sm: 'none',
  md: 'flex',
  lg: 'flex',
  xl: 'flex',
  tablet: 'flex',
  laptop: 'flex',
  desktop: 'flex',

}

const displaySmall = {

  xs: 'flex',
  sm: 'flex',
  md: 'none',
  lg: 'none',
  xl: 'none',
  tablet: 'none',
  laptop: 'none',
  desktop: 'none',

}



const drawerWidth = 240;



//const mdTheme = createTheme();


function PageContent(props) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  let location = useLocation();
  console.log(location)
  const params = useParams();





  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <StandardAppBar open={open} setOpen={setOpen}/>
      
      <StandardSideMenu open={open} setOpen={setOpen}/>




    



      <Box
        component="main"
        sx={{
          borderx: '2px solid blue',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          position: 'relative'
        }}
      >
        <div style={{
          backgroundColorx: 'tan',
          borderx: '2px solid white',
          display: 'flex',
          flexFlow: 'column',
          height: '100vh'
        }}>
          <Toolbar />
          <div style={{
            backgroundColorx: 'gold',
            borderx: '2px solid black',
            flex: 1,
            overflow: 'hidden'
          }}>

            <Box style={{
              height: '100%',
              paddingx: '5px 5px 5px 5px',
              borderx: '1px solid black'
            }}
              sx={{
                display: displayBig
              }}>

              <Paper  sx={{
                display: 'flex',
                flex: 2,
                margin: '1em 1em 1em 1em',
                justifyContent: 'flex-start',
              }} >
                {props.leftContent}
              </Paper>

              {/*<Paper  sx={{
                display: 'flex',
                flex: 6,
                margin: '1em 1em 1em 0em',
                width: '100%'
              }} >*/}
                <div style={{
                  display: 'flex',
                  flex: 7,
                  margin: '1em 1em 1em 0em'
                }}>
                  {props.mainContent}
                </div>
              {/*</Paper>*/}
              
              <Paper  sx={{
                display: 'flex',
                flex: 3,
                margin: '1em 1em 1em 0em',
                width: '100%'
              }} >
                <div style={{
                  height: '100%',
                  width: '100%'
                }}>
                  {props.rightContent}
                </div>
              </Paper>


            </Box>



            <Box style={{
              height: '100%'
            }}
              sx={{
                display: displaySmall,
                flexDirection: 'column'
              }}>


              {/*<Paper  sx={{
                display: 'flex',
                flex: 6,
                margin: '1em 1em 1em 0em',
                width: '100%'
              }} >*/}
                <div style={{
                  display: 'flex',
                  margin: '1em 1em 1em 1em',
                  height: '100%'
                }}>
                  {props.mainContent}
                </div>
              {/*</Paper>*/}


            </Box>




          </div>
        </div>
      </Box>
    </Box>
  );
}

PageContent = inject('stores')(observer(PageContent));
export default inject('stores')(observer(function Dashboard(props) {

  const navigate = useNavigate();
  const params = useParams();

  return <PageContent {...props} />;
}));

/*export default function Dashboard(props) {
  return <PageContent {...props}/>;
}*/