import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars-2';

const StandardTabDetail = (props) => {

    
    


    return (
        <Scrollbars
            
        autoHide
        renderThumbHorizontal={()=>{
            return <span/>
        }}
        style={{ width: '100%', height: '100%', overFlow:'hidden' }}>
          {props.content}
        </Scrollbars>
      );
};


export default inject('stores')(observer(StandardTabDetail));
