import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Box from '@mui/material/Box';

const StandardDetail = (props) => {

    const displayBig = {

        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
        xl: 'flex',
        tablet: 'flex',
        laptop: 'flex',
        desktop: 'flex',
      
      }
      
      const displaySmall = {
        
          xs: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
          tablet: 'none',
          laptop: 'none',
          desktop: 'none',
        
      }

    const wrappedLeftContent = props.leftContent.map((item)=>{

        return <div style={{
            marginBottom:'2em'
        }}>
            {item}
        </div>
    });


    return (
        <Box style={{
            display:'flex',
            marginLeft:'.5em',
            marginRight:'.5em'
        }}>
            <Box sx={{
                display:displayBig,
                flexDirection:'column',
                flex:3,
                justifyContent:'flex-start',
                alignItems:'center'
            }}>
                {wrappedLeftContent}
            </Box>

            <Box sx={{
                display:displayBig,
                flex:9,
                justifyContent:'flex-start',
                alignItems:'flex-start',
                marginLeft:'.5em'
            }}>
                {props.rightContent}

            </Box>

            <Box sx={{
                display:displaySmall,
                flexDirection:'column',
                flex:12,
                justifyContent:'flex-start',
                alignItems:'center',
                marginLeft:'.5em'
            }}>
                {wrappedLeftContent}
                {props.rightContent}

            </Box>

        </Box>
      );
};


export default inject('stores')(observer(StandardDetail));
