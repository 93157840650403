import React, { useState, useEffect } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { inject, observer } from 'mobx-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuthStatus } from './auth/authHooks';
//import SignupForm from './auth/signup';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import Authenticator from './auth/Authenticator';
import HomePage from './pages/HomePage';
import BooksPage from './pages/BooksPage';
import BoxsetsPage from './pages/BoxsetsPage';
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditRequestsPage from './pages/EditRequestsPage';
import ConnectionsPage from './pages/ConnectionsPage';
import WritePage from './pages/WritePage';
import EditPage from './pages/EditPage';
import FormatPage from './pages/FormatPage';
import TrashPage from './pages/TrashPage';



const theme = createTheme({

	palette: {
		type: 'light',
		primary: {
			main: '#009688',
		},
		secondary: {
			main: '#0d30b1',
		},
		background: {
			default: '#eeeeee',
			paper: '#ffffff'
		},
	},
});



const AppWrapper = (props) => {





	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px)'
	})
	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
	const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })




	return (
		<ThemeProvider theme={theme}>




			{/*!props.stores.bookStore.loggedIn &&
			
				<SignupForm />
				
			*/}

			{!props.stores.bookStore.loggedIn &&

				<Authenticator />

			}
			{/*!props.stores.bookStore.loggedIn &&
			
				<SignIn/>
				
			}
			{!props.stores.bookStore.loggedIn &&
			
				<SignUp/>
				
			*/}


			{(props.stores.bookStore.loggedIn == null) &&
				<div>loading...</div>
			}
			{(props.stores.bookStore.loggedIn != null && props.stores.bookStore.loggedIn) &&


				<div >
					{/*<div>
				<h1>Device Test!</h1>
				{isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
				{isBigScreen && <p>You  have a huge screen</p>}
				{isTabletOrMobile && <p>You are a tablet or mobile phone</p>}
				<p>Your are in {isPortrait ? 'portrait' : 'landscape'} orientation</p>
				{isRetina && <p>You are retina</p>}
			</div>*/}
					<BrowserRouter>
						<Routes>
							<Route exact={true} path="/" element={<HomePage />} />
							<Route path="/books" element={<BooksPage />} />
							<Route path="/boxsets" element={<BoxsetsPage/>} />
							<Route path="/editrequests" element={<EditRequestsPage/>} />
							<Route path="/connections" element={<ConnectionsPage/>} />
							<Route path="/write" element={<WritePage/>} />
							<Route path="/edit" element={<EditPage/>} />
							<Route path="/format" element={<FormatPage/>} />
							<Route path="/trashbin" element={<TrashPage/>} />

							
							
							<Route
								path="*"
								element={
										<p>There's nothing here!</p>
								}
							/>
					</Routes>
				</BrowserRouter>
				</div>

			}

		</ThemeProvider >


	);
};


export default inject('stores')(observer(AppWrapper));