import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import DocumentPartEditor from './DocumentPartEditor';
import uuid from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Toolbar from './toolbar/Toolbar';
import { EditingContextProvider } from './EditingContext';

const BookDraftEditor = (props) => {

    let [formatting,setFormatting] = useState({});
    let [selectedQuill,setSelectedQuill] = useState(null);
    let [selectedDocumentPart,setSelectedDocumentPart] = useState(null);
    let [documentPartEditors,setDocumentPartEditors] = useState([]);

    let {bookDraft} = props;
    

    let contextValues = {
        formatting,
        setFormatting,

        selectedQuill,
        setSelectedQuill,

        selectedDocumentPart,
        setSelectedDocumentPart
    }


    useEffect(()=>{
        console.log('calling useEffect...')
        const documentPartEditors = bookDraft.bookDraftDocumentParts.map((bddp, index) => {

            return <DocumentPartEditor bookDraftDocumentPart={bddp}/>
        })

        setDocumentPartEditors(documentPartEditors);

    },bookDraft)
    


    return (
        <div style={{ 
            borderx:'1px solid red',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width: '100%', 
            height: '100%', 
            overFlow: 'hidden' }}>
            <EditingContextProvider value={contextValues}>
            {/*selectedDocumentPart && 
                <div>{selectedDocumentPart.title}</div>
            */}
            <Toolbar />

            <div style={{
                flex:1,
                heightx:"calc(100% - 3em)"
            }}>
                <Scrollbars
                    autoHide
                    renderThumbHorizontal={() => {
                        return <span />
                    }}

                    style={{ width: '100%', height: '100%', overFlow: 'hidden' }}>

                    <div style={{
                        overflow: 'hidden',
                        marginTop:'.5em'
                    }}>
                        {documentPartEditors}
                    </div>
                </Scrollbars>
            </div>
        </EditingContextProvider>
        </div>
    );
};


export default inject('stores')(observer(BookDraftEditor));
