import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Auth } from "aws-amplify";
import { inject, observer } from 'mobx-react';
import SignIn from './SignIn';
import SignUp from './SignUp';







const Authenticator = (props) => {

	const [mode, setMode] = useState('SIGNIN');

	
	return (
		<div>
			{mode=='SIGNIN'&&
				<SignIn setMode={setMode}/>
			}
			
			{mode=='SIGNUP'&&
				<SignUp setMode={setMode}/>
			}
		</div>
	);
}

export default inject('stores')(observer(Authenticator));
