import React, { useState, useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import Quill from 'quill';
import uuid from 'uuid';
import { Typography } from '@material-ui/core';
import EditingContext from './EditingContext';
import BlurbsBlot from './blots/BlurbsBlot';
import AlsobysBlot from './blots/AlsobysBlot';

require('quill/dist/quill.snow.css')

Quill.register(BlurbsBlot, true);
Quill.register(AlsobysBlot, true);

/*Quill.register(TOCBlot, true);
Quill.register(DecoratedBreakBlot2, true);
Quill.register(NotesBlot, true);
Quill.register(ContributorsBlot, true);
Quill.register(PublisherBlot, true);
Quill.register(ImageBlot4, true);
Quill.register(VerseBlot, true);
Quill.register(AttributionBlot, true);
Quill.register(BlockQuoteBlot, true);
Quill.register(SubheadBlot, true);  
Quill.register(EditingDeleteBlot, true);
Quill.register(EditingFormatBlot, true);
Quill.register(EditingInsertBlot, true);
Quill.register(EditingMovedTombstoneBlot, true);
Quill.register(EditingMovedDestinationBlot, true);
Quill.register(EditingNewLineBlot, true);
Quill.register(EditingInsertNewLineBlot, true);
Quill.register(Link2, true);*/

const QuillEditor = (props) => {

    const [editorId, setEditorId] = React.useState('editor' + uuid.v1());
    const [scrollingContainerId, setScrollingContainerId] = React.useState('scrollingContainer' + uuid.v1());
    
    const editingContext = useContext(EditingContext);

    const {documentPart} = props;

    useEffect(() => {

        console.log('setting up quill')
        let quill = new Quill('#' + editorId, {
            theme: 'snow',
            modules: {
                toolbar: false
            },
            formats: [
                'bold',
                'underline',
                'italic',
                'strike',
                'blockquote',
                'attribution',
                'verse',
                'subhead',
                'fontSizeBM',
                'fontVariant',
                'fontFamily',
                'publisher',
                'contributors',
                'blurbs',
                'alsobys',
                //'link',
                'link2',
                //'list',
                'align',
                //'image',
                'image4',
                'tocs',
                'script',
                'editingDeleteBlot',
                'editingInsertBlot',
                'editingFormatBlot',
                'editingMovedTombstoneBlot',
                'editingMovedDestinationBlot',
                'editingNewLineBlot',
                'editingInsertNewLineBlot',
                'decoratedBreak'
                //'indent'
            ],
            placeholder: 'Begin writing here...',
            scrollingContainer: '#' + scrollingContainerId
        });

        quill.on('text-change', function (delta, oldDelta, source) {

            console.log(delta);
        });

        quill.on('selection-change', function (range, oldRange, source) {

            if (source && (source == 'api' || source == 'silent')) {
                return;
            }
            //that.props.stores.bookStore.activeSceneQuill = that.state.quill;

            if (range) {
                //that.props.stores.bookStore.currentSelectionFormatting = observable(quill.getFormat(range));
                let formatting = quill.getFormat(range);
                console.log(formatting);
                editingContext.setFormatting(formatting);
                editingContext.setSelectedDocumentPart(documentPart);
                editingContext.setSelectedQuill(quill);
                
            }
        });





        if (props.delta) {
            quill.setContents(props.delta, 'silent');
        }
    },[]);






    return (
        <div

            autoHide
            renderThumbHorizontal={() => {
                return <span />
            }}
            style={{ width: '100%', height: '100%', overFlow: 'hidden' }}>
            {props.showBreak &&
                <Typography variant="h4">***</Typography>
            }
            <div id={editorId} style={{
                overflow: 'hidden',
                border: '0em'
            }}>

            </div>
        </div>
    );
};


export default inject('stores')(observer(QuillEditor));
