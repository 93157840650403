import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

const TreeNode = () =>{

  return (
    <span>a node</span>
  )
}

const createNode = ({data}) =>{

  let treeNode = new TreeNode({title:data.title});

  treeNode.childTreeNodes = data.children.map((childData)=>{
    return new createNode({data:childData})
  });

  return treeNode;
}

const StandardTree = (props) => {

    const data = {
      label: 'root',
      children:[
        {
          label:'child1'
        },
        {
          label:'child2'
        },
        {
          label:'child3'
        }
      ]
    }

    return (
       <div>
          aaaaa
       </div>
    );
};


export default inject('stores')(observer(StandardTree));