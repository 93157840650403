import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import uuid from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';


const ToolbarItem = (props) => {

    const toolbarStyle = {
        width: '.75em',
        height: '1em',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center'
    }

    

    const handleToggle = () => {

    }

    return (
        <div style={toolbarStyle}  onClick={handleToggle}>
            {props.icon}
        </div>
                
    );
};


export default inject('stores')(observer(ToolbarItem));
