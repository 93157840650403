import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Auth } from "aws-amplify";
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { useParams, useNavigate, useLocation } from "react-router-dom";

const StandardAppBar = (props) => {

    const navigate = useNavigate();
    let location = useLocation();

    const drawerWidth = 240;

    const displayBig = {

        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
        xl: 'flex',
        tablet: 'flex',
        laptop: 'flex',
        desktop: 'flex',
      
      }
      
      const displaySmall = {
      
        xs: 'flex',
        sm: 'flex',
        md: 'none',
        lg: 'none',
        xl: 'none',
        tablet: 'none',
        laptop: 'none',
        desktop: 'none',
      
      }


      const toggleDrawer = () => {
        props.setOpen(!props.open);
      };
    
    
      const onLogout = event => {
        event.preventDefault();
    
    
        Auth.signOut()
          .then(data => {
            console.log(data);
    
            props.stores.bookStore.loggedIn = false;
    
          })
          .catch(err => {
            console.log(err);
    
          })
      };


      const getPageTitle = () => {

  
        switch (location.pathname) {
            case '/':
              return 'Homexxx';
            break;
      
            case '/books':
              return 'Books'
            break;
      
            case '/boxsets':
              return 'Boxsets'
            break;
      
            case '/editrequests':
              return 'Edit Requests'
            break;
      
            case '/connections':
              return 'Connections'
            break;
      
            case '/write':
              return 'Book Title'
            break;
      
            case '/edit':
              return 'Edit Book Title'
            break;
      
            case '/format':
              return 'Format Book Title'
            break;
      
        
          default:
            break;
        }
      }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'props.open',
      })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(props.open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }));

    

    return (
        <AppBar position="absolute" open={props.open} sx={{
        }}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              display: displayBig,
              marginRight: '36px',
              ...(props.open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {getPageTitle()}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={(e) => { onLogout(e) }}>
            <Badge color="secondary">
              <LogoutIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    );
};


export default inject('stores')(observer(StandardAppBar));
